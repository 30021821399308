<template>
  <div class="intelligent-monitor x-background-color x-box-shadow">
    <div class="search-pane">
      <Cascader
        :data="farmScene"
        v-model="selFarmScene"
        :clearable="false"
        style="width: 300px"
      ></Cascader>
    </div>
    <div class="monitor-box">
      <div
        class="monitor-value"
        :style="{ width: videoList.length === 0 ? '100%' : '64%' }"
      >
        <div class="table border-color">
          <x-table
            height="400px"
            border
            :columns="table.columns"
            :data="table.data"
            :loading="table.loading"
          ></x-table>
        </div>
      </div>
      <div class="monitor-video border-color" v-if="videoList.length > 0">
        <div class="selection-box">
          <Select v-model="selVideo" style="width: 250px">
            <Option
              v-for="(item, index) in videoList"
              :value="index"
              :key="index"
              >{{ item.name }}</Option
            >
          </Select>
          <p>共：{{ videoList.length }} 个</p>
        </div>
        <div class="video-play-window" id="videoBox">
          <player :videoInfo="videoList[selVideo]"></player>
        </div>
      </div>
    </div>
    <div class="single-data-container border-color">
      <div class="left-place">
        <div
          @click="() => (showType = 1)"
          :class="[
            'type-button',
            'border-color',
            showType == 1 ? 'current-type-button' : '',
          ]"
        >
          统<br />计<br />图
        </div>
        <div
          @click="() => (showType = 2)"
          :class="[
            'type-button',
            'border-color',
            showType == 2 ? 'current-type-button' : '',
          ]"
        >
          统<br />计<br />表
        </div>
      </div>
      <div class="right-place">
        <div style="height: 100%; width: 100%" v-show="showType == 1">
          <div class="chart-name">
            {{
              historyDevice
                ? historyDevice.groupName +
                  " / " +
                  historyDevice.name +
                  "(" +
                  historyDevice.sensorCategoryName +
                  ")"
                : ""
            }}
          </div>
          <div class="line-chart-box" ref="lineChart"></div>
        </div>
        <div v-show="showType == 2">
          <div class="chart-name">
            {{
              historyDevice
                ? historyDevice.groupName +
                  " / " +
                  historyDevice.name +
                  "(" +
                  historyDevice.sensorCategoryName +
                  ")"
                : ""
            }}
          </div>
          <div class="line-chart-box">
            <x-table
              newStyle
              class="row-border-table"
              :data="[{}]"
              :columns="computedColumns"
            >
            </x-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CU from "@/common/util";
import * as echarts from "echarts";
import player from "@/components/player";
// import xTable from '@/components/xTable'
import xTable from './xTable'
export default {
  name: "",
  components: {
    player,
    xTable
  },
  data() {
    return {
      table: {
        columns: [
          {
            type: "expand",
            width: 76,
            render: (h, { row }) => {
              let data = row.childCategoryList || [];
              return (
                <xTable
                  highlight-row={true}
                  onOn-current-change={(selectRow) =>
                    this.checkRowData(selectRow)
                  }
                  height="100%"
                  show-header={false}
                  columns={this.subColumns}
                  border
                  data={data}
                ></xTable>
              );
            },
          },
          {
            title: "监测要素",
            key: "groupName",
          },
          {
            title: "实况值",
            key: "measure",
          },
          {
            title: "更新时间",
            key:"updateTime"
          },
          {
            title: "状态",
            width: 80,
          },
        ],
        data: [],
        loading: false,
      },
      subColumns: [
        {
          title: "图标",
          width: 80,
          render: (h, { row }) => {
            if (!row.imgUrl) return <span></span>;
            return (
              <span>
                <img style="width:40px;height:40px" src={row.imgUrl} />{" "}
              </span>
            );
          },
        },
        {
          title: "监测要素",
          key: "sensorCategoryName",
        },
        {
          title: "实况值",
          render: (h, { row }) => {
            console.log(row,'row')
            return (
               
              <span>
             
                {row.childItemList[0].measure}({row.childItemList[0].sensorCategoryUnit})
                <Icon type="md-trending-up"></Icon>
              </span>
            );
          },
        },
        {
          title: "更新时间",
          key: "updateTime",
          render: (h, { row }) => {
            let str = row.childItemList[0].updateTime;
            return <span>{str}</span>;
          },
        },
        {
          title: "状态",
          width: 80,
          render: (h, { row }) => {
            let str = row.childItemList[0].status == 1 ? "正常" : "断线";
            return <span>{str}</span>;
          },
        },
      ],
      //基地场景
      farmScene: [],
      //选中的基地场景
      selFarmScene: [],

      // 当前展示样式（1统计图/2统计表)
      showType: 1,
      // 历史数据查询设备
      historyDevice: null,
      // 历史数据查询
      historyData: [],
      // 线形图实例
      lineChart: null,
      //视频列表
      videoList: [],
      //选中的视频
      selVideo: 0,
    };
  },
  computed: {
    //统计表columns
    computedColumns() {
      return this.historyData.map((item, index) => ({
        title: item.hourOne + "时",
        width: 80,
        align: "center",
        render: (h) => {
          let str = this.historyData[index].value || "--";
          return <span>{str}</span>;
        },
      }));
    },
  },
  methods: {
    //获取基地场景
    getFarmScene() {
      this.$post(this.$api.PRODUCTION_AREA.FARMSCENE, {
        siteType: "2",
      }).then((res) => {
        this.farmScene = CU.formatTreeData(res, "id", "name", "sceneList");
        this.selFarmScene = [
          this.farmScene[0].value,
          this.farmScene[0].children[0].value,
        ];
      });
    },
    //获取监测值
    getMonitorList() {
      this.table.loading = true;
      this.$post(this.$api.MONITOR.MONITOR_TREE, {
        farmId: this.selFarmScene[0],
        sceneId: this.selFarmScene[1],
        sourceType: 0,
      })
        .then((res) => {
          // let tableData = [];
          // for (let i = 0; i < res.list.length; i++) {
          //   let item = res.list[i];
          //   let childCategoryList = [];
          //   for (let j = 0; j < item.childCategoryList.length; j++) {
          //     childCategoryList.push(
          //       ...item.childCategoryList[j].childItemList
          //     );
          //   }
          //   item.childCategoryList = childCategoryList;
          //   tableData.push(item);
          // }
          console.log(res)
          this.table.data = res.list;
          try {
            tableData[0]._expanded = true;
            this.checkRowData(tableData[0].childCategoryList[0]);
          } catch (e) {
            // console.log(e);
          }
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    //获取统计图数据
    checkRowData(row) {
      this.historyDevice = null;
      this.historyData = [];
      this.$post(this.$api.MONITOR.MONITOR_CHART, {
        guid: row.childItemList[0].guid,
        pageNo: 1,
        pageSize: 24,
      }).then((res) => {
        if (!res || res.length === 0) return;
        this.historyDevice = { ...res[0], child: null };
        this.historyData = res[0].child;
      });
    },
    //获取视频列表
    getVideo() {
      this.$post(this.$api.BUSSDEVICEVIDEO.LIST, {
        farmId: this.selFarmScene[0],
        sceneId: this.selFarmScene[1],
        pageNo: 1,
        pageSize: 99,
      }).then((res) => {
        this.videoList = res.list;
      });
    },
    // 设置线图
    setLineChart() {
      if (!this.lineChart) this.lineChart = echarts.init(this.$refs.lineChart);
      let options = {
        grid: {
          containLabel: true,
          top: 10,
          left: 10,
          right: 10,
          bottom: 10,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          data: this.historyData.map((item) => item.hourOne + "时"),
          axisLine: {
            lineStyle: {
              color: "#2d8cf0",
            },
          },
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#2d8cf0",
            },
          },
        },
        series: [
          {
            type: "line",
            data: this.historyData.map((item) => item.value || 0),
          },
        ],
        color: ["#2d8cf0"],
      };
      this.lineChart.setOption(options);
    },
    //浏览器窗口发生变化
    resize() {
      if (!this.lineChart) return;
      this.lineChart.resize();
    },
  },
  mounted() {
    this.getFarmScene();
    window.addEventListener("resize", this.resize);
  },
  watch: {
    selFarmScene: {
      handler(n, o) {
        this.getMonitorList();
        this.getVideo();
      },
      deep: true,
    },
    historyData(val) {
      if (!val || val.length == 0) {
        if (this.lineChart) this.lineChart.clear();
        return;
      }
      this.setLineChart();
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
.intelligent-monitor {
  width: 100%;
  height: 100%;
  padding: 16px;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .search-pane {
    margin-bottom: 16px;
  }
  .monitor-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .monitor-value {
      .table {
        height: 400px;
        border-style: solid;
        border-width: 1px;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        /deep/ .ivu-table-body {
          overflow-x: hidden;
        }
      }
    }
    .monitor-video {
      width: 35%;
      border-style: solid;
      border-width: 1px;
      height: 400px;
      padding: 16px;
      .selection-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }
      .video-play-window {
        height: 320px;
      }
      /deep/
        .ivu-select-single
        .ivu-select-selection
        .ivu-select-selected-value {
        text-align: center;
      }
      /deep/ .ivu-select-item {
        text-align: center;
      }
    }
  }
  .single-data-container {
    margin-top: 16px;
    width: 100%;
    height: 300px;
    display: flex;
    border-width: 1px;
    border-style: solid;
    .left-place {
      width: 60px;
      height: 100%;
      .type-button {
        display: flex;
        height: 50%;
        align-items: center;
        justify-content: center;
        border-right-width: 1px;
        border-right-style: solid;
        cursor: pointer;
      }
      .current-type-button {
        background: #2d8cf0;
        color: #fff;
      }
    }
    .right-place {
      width: calc(~"100% - 60px");
      .chart-name {
        font-size: 16px;
        font-weight: bold;
        padding: 16px;
      }
      .line-chart-box {
        height: calc(~"100% - 56px");
        width: 100%;
      }
    }
  }
  .no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    img {
      width: 198px;
      height: 118px;
    }
  }
  /deep/ td.ivu-table-expanded-cell {
    padding: 0;
  }
  /deep/ .ivu-table-border:after {
    height: 0;
  }
  /deep/ .ivu-table-border th {
    border-right: none;
  }
  /deep/ .ivu-table-border td {
    border-right: none;
  }
  /deep/ .ivu-table-border tr:first-child td {
    border-top-width: 1px;
    border-top-style: solid;
  }
  /deep/ .ivu-table-wrapper-with-border {
    border: none;
  }
}
</style>