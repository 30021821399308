<template>
  <div :style="{ height: height ? height : '100%' }" class="page">
    <slot name="header"></slot>
    <div
      :class="[
        'x-table',
        'x-table-pane',
        newStyle ? 'x-table-bg' : '',
        selectable && selectedCount && !loading && data.length > 0
          ? 'x-table-selectable'
          : '',
      ]"
    >
      <Table
        :show-header="showHeader"
        :no-data-text="noDataText"
        @on-row-dblclick="
          (data, index) => $emit('on-row-dblclick', data, index)
        "
        @on-row-click="(data, index) => $emit('on-row-click', data, index)"
        @on-selection-change="onSelectionChange"
        @on-current-change="(a, b) => $emit('on-current-change', a, b)"
        :columns="computed_columns"
        :data="data"
        :loading="loading"
        :highlight-row="highlightRow"
        :border="border"
        :row-key="rowKey"
        :size="size"
        @on-select="(rows, row) => $emit('on-select', rows, row)"
        @on-select-all="(rows) => $emit('on-select-all', rows)"
        @on-select-cancel="(rows, row) => $emit('on-select-cancel', rows, row)"
        @on-select-all-cancel="(rows) => $emit('on-select-all-cancel', rows)"
      ></Table>
      <p
        v-if="selectable && selectedCount && !loading && data.length > 0"
        class="x-tooltip"
      >
        <span>已选择&nbsp;{{ select_count }}</span>
        <span>记录数&nbsp;{{ data.length }}</span>
      </p>
    </div>
    <slot name="footer"></slot>
  </div>
</template>
<script>
export default {
  props: {
    // 是否使用新版风格(暗黑色)
    newStyle: Boolean,
    noDataText: {
      type: String,
      default: "暂无数据",
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    height: {
      type: [Number, String],
      default: "100%",
    },
    // 是否显示已选择数量(selectable开启时)
    selectedCount: {
      type: Boolean,
      default: true,
    },
    border: {
      type: Boolean,
      default: false,
    },
    rowKey: String,
    highlightRow: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "default",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      //   当前选择数量
      select_count: 0,
    };
  },
  computed: {
    computed_columns() {
      return this.selectable
        ? [
            {
              type: "selection",
              width: 52,
            },
            ...this.columns,
          ]
        : this.columns;
    },
  },
  methods: {
    onSelectionChange(selection) {
      this.select_count = selection.length;
      this.$emit("on-selection-change", selection);
    },
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>