var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",style:({ height: _vm.height ? _vm.height : '100%' })},[_vm._t("header"),_c('div',{class:[
      'x-table',
      'x-table-pane',
      _vm.newStyle ? 'x-table-bg' : '',
      _vm.selectable && _vm.selectedCount && !_vm.loading && _vm.data.length > 0
        ? 'x-table-selectable'
        : '',
    ]},[_c('Table',{attrs:{"show-header":_vm.showHeader,"no-data-text":_vm.noDataText,"columns":_vm.computed_columns,"data":_vm.data,"loading":_vm.loading,"highlight-row":_vm.highlightRow,"border":_vm.border,"row-key":_vm.rowKey,"size":_vm.size},on:{"on-row-dblclick":(data, index) => _vm.$emit('on-row-dblclick', data, index),"on-row-click":(data, index) => _vm.$emit('on-row-click', data, index),"on-selection-change":_vm.onSelectionChange,"on-current-change":(a, b) => _vm.$emit('on-current-change', a, b),"on-select":(rows, row) => _vm.$emit('on-select', rows, row),"on-select-all":(rows) => _vm.$emit('on-select-all', rows),"on-select-cancel":(rows, row) => _vm.$emit('on-select-cancel', rows, row),"on-select-all-cancel":(rows) => _vm.$emit('on-select-all-cancel', rows)}}),(_vm.selectable && _vm.selectedCount && !_vm.loading && _vm.data.length > 0)?_c('p',{staticClass:"x-tooltip"},[_c('span',[_vm._v("已选择 "+_vm._s(_vm.select_count))]),_c('span',[_vm._v("记录数 "+_vm._s(_vm.data.length))])]):_vm._e()],1),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }